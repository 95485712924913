import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/home/Index'),
    redirect: '/dashboard',
    children: [
      {
        path: '/homework',
        name: '作业发布',
        component: () => import('@/views/homework/index'),
        meta: { title: '作业发布' }
      },
      {
        path: '/mywork',
        name: '我的作业',
        component: () => import('@/views/homework/mywork'),
        meta: { title: '我的作业' }
      },
      {
        path: '/examinework',
        name: '作业查看',
        component: () => import('@/views/homework/examinework'),
        meta: { title: '作业查看' }
      },
      {
        path: '/shixunstudy',
        name: '实训学习',
        component: () => import('@/views/onlineLearn/shixunstudy'),
        meta: { title: '实训学习' }
      },
      {
        path: '/BBS',
        name: '学习论坛',
        component: () => import('@/views/post/BBS'),
        meta: { title: '学习论坛' }
      },
      {
        name: 'dashboard',
        path: '/dashboard',
        component: () => import('@/views/dashboard/index'),
        meta: { title: '首页' }
      },
      {
        name: 'tag',
        path: '/tag/:name',
        component: () => import('@/views/tag/Tag'),
        meta: { title: '标签列表' }
      },
      // 检索
      {
        name: 'search',
        path: '/search/:key',
        component: () => import('@/views/post/Search'),
        meta: { title: '检索' }
      },
      { // 在线学习
        name: 'online-learn',
        path: '/onlineLearn/OnlineLearn',
        component: () => import('@/views/onlineLearn/OnlineLearn'),
        meta: { title: '在线学习', requireAuth: true }
      },
      {
        name: 'Answer',
        path: '/Answer',
        component: () => import('@/views/answer/Answer'),
        meta: { title: '直通杨老师' }
      },
      {
        name: 'Notes',
        path: '/Notes',
        component: () => import('@/views/notes/Notes'),
        meta: { title: '咨询札记' }
      }
    ]
  },
  {
    path: '/pageLayout',
    name: 'pageLayout',
    component: () => import('@/components/layout/PageLayout'),
    children: [
      // 详情
      {
        name: 'post-detail',
        path: '/post/:id',
        component: () => import('@/views/post/Detail'),
        meta: { title: '详情' }
      },
      // 编辑
      {
        name: 'topic-edit',
        path: '/topic/edit/:id',
        component: () => import('@/views/post/Edit'),
        meta: { title: '编辑', requireAuth: true, keepAlive: true, component: 'Edit' }
      },
      // 发布
      {
        name: 'post-create',
        path: '/post/create/:type',
        component: () => import('@/views/post/Create'),
        meta: { title: '信息发布', requireAuth: true, keepAlive: true, component: 'Create' }
      },
      {
        name: 'LMind',
        path: '/LMind',
        component: () => import('@/views/mind/YsMindList'),
        meta: { title: 'LMind', requireAuth: true }
      },
      { // 在线学习预览
        name: 'threebenefit',
        path: '/threebenefit',
        component: () => import('@/views/threebenefit/threeBenefitList'),
        meta: { title: '精益底层逻辑', requireAuth: true }
      },
      { // 在线学习预览
        name: 'five',
        path: '/five',
        component: () => import('@/views/fiveask/YsMindList'),
        meta: { title: '5WHAT', requireAuth: true }
      },
      {
        name: 'cgformList',
        path: '/cgformList',
        component: () => import('@/views/cgformList'),
        meta: { title: '客户周报', requireAuth: true }
      },{
        name: 'fiveElements',
        path: '/fiveElements',
        component: () => import('@/components/layout/IframePageView'),
        meta: { internalOrExternal: true, url: process.env.VUE_APP_ADMIN_URL + '/online/cgformList/923b237a0ea04e26aec9de2640b61e23?token=${token}&groupCompany=0&from=bbs', requireAuth: true }
        // component: () => import('@/views/fiveElements/fiveElements'),
        // meta: { title: '五要素看板', requireAuth: true }
      }, {
        name: 'faultdiagram',
        path: '/faultdiagram',
        component: () => import('@/views/faultdiagram'),
        meta: { title: '故障图', requireAuth: true }
      },
      // 用户主页
      {
        name: 'user',
        path: '/member/home/:username',
        component: () => import('@/views/user/Profile'),
        meta: { title: '用户主页', requireAuth: true }
      },
      // 登录
      {
        name: 'login',
        path: '/login',
        component: () => import('@/views/user/Login'),
        meta: { title: '登录' }
      },
      {
        path: '/register',
        name: 'register',
        component: () => import('@/views/user/Register'),
        meta: { title: '注册' }
      },
      {
        path: '/forgetPassword',
        name: 'forgetPassword',
        component: () => import('@/views/user/ForgetPassword'),
        meta: { title: '忘记密码' }
      },
      // 用户设置
      {
        name: 'user-setting',
        path: '/member/setting/:username',
        component: () => import('@/views/user/Setting'),
        meta: { title: '设置', requireAuth: true }
      },
      // 修改密码
      {
        name: 'resetPassword',
        path: '/member/resetPassword/:username',
        component: () => import('@/views/user/ResetPassword'),
        meta: { title: '修改密码', requireAuth: true }
      },
      {
        name: 'UserAnnouncementList',
        path: '/system/UserAnnouncementList',
        component: () => import('@/views/system/UserAnnouncementList'),
        meta: { title: '系统通知' }
      }
    ]
  },
  { // 在线学习预览
    name: 'online-preview',
    path: '/onlineLearn/OnlinePreview/:id',
    component: () => import('@/views/onlineLearn/OnlinePreview'),
    meta: { title: '在线学习预览', requireAuth: true }
  },
  { // 我的作业预览
    name: 'work-preview',
    path: '/homework/workPreview/:filename',
    component: () => import('@/views/homework/workPreview'),
    meta: { title: '作业预览', requireAuth: true }
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/error/404'),
    meta: { title: '404-NotFound' }
  },
  {
    path: '*',
    redirect: '/404',
    hidden: true
  },
  {
    path: '/qzkbv1', // 外部链接打开清障看板
    name: 'qzkbv1',
    component: () => import('@/components/layout/IframePageView'),
    meta: { internalOrExternal: true, url: process.env.VUE_APP_ADMIN_URL + '/qzkbv1?token=${token}&groupCompany=0&from=bbs', requireAuth: true }
  },
  {
    path: '/obstacle', // 外部链接打开清障看板
    name: 'obstacle',
    component: () => import('@/components/layout/IframePageView'),
    meta: { internalOrExternal: true, url: process.env.VUE_APP_ADMIN_URL + '/obstacle_other?token=${token}&groupCompany=0&from=bbs', requireAuth: true }
  },
  {
    path: '/perception', // 外部链接打开清障看板
    name: 'perception',
    component: () => import('@/components/layout/IframePageView'),
    meta: { internalOrExternal: true, url: process.env.VUE_APP_ADMIN_URL + '/online/cgformList/4cbfbdad3b3b4a3298814218a403a6b0?token=${token}&groupCompany=0&from=bbs', requireAuth: true }
  },
  {
    path: '/causeeffect', // 外部链接打开清障看板
    name: 'causeeffect',
    component: () => import('@/components/layout/IframePageView'),
    meta: { internalOrExternal: true, url: process.env.VUE_APP_ADMIN_URL + '/causeeffect?standingBookTitle=standingBookTitle&token=${token}&groupCompany=0&from=bbs', requireAuth: true }
  }
]

try {
  const originalPush = VueRouter.prototype.push
  VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err)
  }
} catch (e) {
  console.log(e)
}

const router = new VueRouter({
  mode: 'history',
  routes
})
export default router

